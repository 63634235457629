import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextField from '@mui/material/TextField';

const typeDocOptions = [
  'CNI FR (Nouvelle)',
  'CNI FR (Ancienne)',
  'Passeport',
  'Titre de séjour',
  'CNI Etrangère',
];

const civiliteOptions = ['Homme', 'Femme'];

const ancienneteOptions = [
  { value: '', label: 'Tout' }, // Option pour ne pas filtrer
  { value: '0', label: 'Nouveau' },
  { value: '1', label: '3 mois à 1 an' },
  { value: '2', label: '1 an à 3 ans' },
  { value: '3', label: '3 ans à 6 ans' },
  { value: '4', label: '+ 6 ans' },
];

const typeContratOptions = [
  'CDI',
  'CDD',
  'CTT (Intérim)',
  'Intermitent',
  'Saisonier',
  'Retraité',
  'Retraité militaire',
  'Militaire',
  'Gérant',
  'Autre',
];

const AdvancedSearch = ({ onSearch, onReset, countries }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(isMobile); // Développe par défaut sur mobile
  const [searchParams, setSearchParams] = useState({
    typesPersonne: {
      Noir: false,
      Arabe: false,
    },
    domiciliation: [],
    saveSearch: false,
  });

  // Générer les options de domiciliation de 1 à 99
  const domiciliationOptions = Array.from({ length: 99 }, (_, i) => i + 1);

  // Sauvegarder dans le localStorage
  const saveSearchParams = (params) => {
    localStorage.setItem('searchParams', JSON.stringify(params));
  };

  const loadSearchParams = () => {
    const savedParams = localStorage.getItem('searchParams');
    if (savedParams) {
      const parsedParams = JSON.parse(savedParams);
      return parsedParams; // Retourner les paramètres chargés
    }
    return null; // Retourner null si aucun paramètre n'est trouvé
  };

  const handleSearch = () => {
    // Sauvegarder uniquement si la case est cochée
    if (searchParams.saveSearch) {
      saveSearchParams(searchParams);
    }
    onSearch(searchParams);
    setExpanded(false);
  };

  // Handle change for the select
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevParams) => ({ ...prevParams, [name]: value }));
  };

  // Function to handle "Select All" functionality
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        domiciliation: domiciliationOptions.map((option) => option), // Coche toutes les options
      }));
    } else {
      setSearchParams((prevParams) => ({
        ...prevParams,
        domiciliation: [], // Déselectionne toutes
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const [field, type] = name.split('-');
    if (field === 'typesPersonne') {
      setSearchParams((prevParams) => ({
        ...prevParams,
        typesPersonne: {
          ...prevParams.typesPersonne,
          [type]: checked,
        },
      }));
    } else {
      setSearchParams((prevParams) => ({ ...prevParams, [name]: checked }));
    }
  };

  const handleReset = () => {
    setSearchParams({
      typesPersonne: {
        Noir: false,
        Arabe: false,
      },
      domiciliation: [],
      saveSearch: false, // Reset saveSearch to false
    });
    localStorage.removeItem('searchParams'); 
    onReset();
  };

  useEffect(() => {
    const loadedParams = loadSearchParams();
    if (loadedParams) {
      setSearchParams(loadedParams);
    }
  }, [isMobile]); // Retirez onSearch ici

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='h7' sx={{ color: theme.palette.primary.dark }}>
          Recherche avancée
        </Typography>
        <Button
          onClick={() => setExpanded(!expanded)}
          endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{
            color: theme.palette.primary.main,
            textTransform: 'none',
          }}
        >
          {expanded ? 'Réduire' : 'Développer'}
        </Button>
      </Box>
      <Collapse in={expanded} unmountOnExit>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Type de document</Typography>
              <Select
                name='typeDoc'
                value={searchParams.typeDoc || ''}
                onChange={handleSelectChange}
                displayEmpty
                fullWidth
                size='small'
              >
                <MenuItem value=''>
                  <em>Type de document</em>
                </MenuItem>
                {typeDocOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Pays</Typography>
              <Select
                name='country'
                value={searchParams.country || ''}
                onChange={handleSelectChange}
                displayEmpty
                fullWidth
                size='small'
              >
                <MenuItem value=''>
                  <em>Pays</em>
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Type de personne</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name='typesPersonne-Noir'
                    checked={searchParams.typesPersonne.Noir}
                    onChange={handleCheckboxChange}
                  />
                }
                label='Black'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='typesPersonne-Arabe'
                    checked={searchParams.typesPersonne.Arabe}
                    onChange={handleCheckboxChange}
                  />
                }
                label='Maghrébin'
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Civilité</Typography>
              <Select
                name='civilite'
                value={searchParams.civilite || ''}
                onChange={handleSelectChange}
                displayEmpty
                fullWidth
                size='small'
              >
                <MenuItem value=''>
                  <em>Civilité</em>
                </MenuItem>
                {civiliteOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Année de naissance</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name='birthYearFrom'
                    type='number'
                    label='De'
                    value={searchParams.birthYearFrom || ''}
                    onChange={handleSelectChange}
                    fullWidth
                    size='small'
                    placeholder='Ex: 1980'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='birthYearTo'
                    type='number'
                    label='À'
                    value={searchParams.birthYearTo || ''}
                    onChange={handleSelectChange}
                    fullWidth
                    size='small'
                    placeholder='Ex: 2000'
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Revenu imposable</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name='revenueFrom'
                    type='number'
                    label='De'
                    value={searchParams.revenueFrom || ''}
                    onChange={handleSelectChange}
                    fullWidth
                    size='small'
                    placeholder='Ex: 10000'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name='revenueTo'
                    type='number'
                    label='À'
                    value={searchParams.revenueTo || ''}
                    onChange={handleSelectChange}
                    fullWidth
                    size='small'
                    placeholder='Ex: 50000'
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Nombre de parts fiscales</Typography>
              <TextField
                name='partsCount'
                type='number'
                value={searchParams.partsCount || ''}
                onChange={handleSelectChange}
                fullWidth
                size='small'
                placeholder='Ex: 2'
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Domiciliation</Typography>
              <FormControl fullWidth size='small'>
                <InputLabel id='domiciliation-select-label'>
                  Sélectionnez la ou les domiciliations
                </InputLabel>
                <Select
                  labelId='domiciliation-select-label'
                  name='domiciliation'
                  multiple
                  value={searchParams.domiciliation || []}
                  onChange={(e) => handleSelectChange(e, 'domiciliation')}
                  renderValue={(selected) => selected.join(', ')}
                >
                  <MenuItem>
                    <Checkbox
                      checked={searchParams.domiciliation.length === domiciliationOptions.length} // Si toutes les options sont sélectionnées
                      onChange={handleSelectAll}
                    />
                    <ListItemText primary='Tout cocher' />
                  </MenuItem>
                  {domiciliationOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={searchParams.domiciliation.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Ancienneté du poste</Typography>
              <Select
                name='anciennete'
                value={searchParams.anciennete || ''}
                onChange={handleSelectChange}
                displayEmpty
                fullWidth
                size='small'
              >
                {ancienneteOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Type de contrat</Typography>
              <Select
                name='typeContrat'
                value={searchParams.typeContrat || ''}
                onChange={handleSelectChange}
                displayEmpty
                fullWidth
                size='small'
              >
                <MenuItem value=''>
                  <em>Type de contrat</em>
                </MenuItem>
                {typeContratOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Présence de RIB sur FDP</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name='hasRib'
                    checked={searchParams.hasRib || false} // Assurez-vous qu'il est dans searchParams
                    onChange={handleCheckboxChange}
                  />
                }
                label='Oui'
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Présence de QRCode sur FDP</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name='hasQRCode'
                    checked={searchParams.hasQRCode || false} // Assurez-vous qu'il est dans searchParams
                    onChange={handleCheckboxChange}
                  />
                }
                label='Oui'
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
              <Typography variant='subtitle1'>Filtrer par document PDF ou non</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name='pdf_identity'
                    checked={searchParams.pdf_identity === true}
                    onChange={(e) => handleCheckboxChange(e, 'pdf_identity')}
                  />
                }
                label="Document d'identité en PDF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='pdf_impots'
                    checked={searchParams.pdf_impots === true}
                    onChange={(e) => handleCheckboxChange(e, 'pdf_impots')}
                  />
                }
                label="Document d'imposition en PDF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='pay_pdf'
                    checked={searchParams.pay_pdf === true}
                    onChange={(e) => handleCheckboxChange(e, 'pay_pdf')}
                  />
                }
                label='Bulletins de salaire en PDF'
              />
            </Paper>
          </Grid>
        </Grid>
        {isMobile && (
        <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name='saveSearch'
                  checked={searchParams.saveSearch}
                  onChange={handleCheckboxChange}
                />
              }
              label='Sauvegarder ma recherche' // Déplacez la case ici
            />
          </Grid>
        )}
        <Box display='flex' justifyContent='right' sx={{ mt: 2 }}>
        {isMobile === false && (
        <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name='saveSearch'
                  checked={searchParams.saveSearch}
                  onChange={handleCheckboxChange}
                />
              }
              label='Sauvegarder ma recherche' // Déplacez la case ici
            />
          </Grid>
        )}
          <Button variant='outlined' color='secondary' onClick={handleReset} sx={{ mr: 2 }}>
            Réinitialiser
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSearch}
            disabled={Object.keys(searchParams).length === 0}
          >
            Rechercher
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default AdvancedSearch;
